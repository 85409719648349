import { useNavigate } from 'react-router-dom';
import PrimaryLayout from '../../layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { fetchBusinessCategories, fetchOrganizations } from '../../redux/actions/apiActions';
import OrganizationCard from './OrganizationCard';
import { useEffect, useState } from 'react';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import { Box, Chip, Icon } from '@mui/material';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import getDistBetweenCordinates from '../../utils/getDistBetweenCordinates';
import SkeletonCard from '../../components/Loaders/SkeletonCard';
import HorizontalItems from '../../components/HorizontalItems/HorizontalItems';
import SearchBar from '../../components/SearchBar/SearchBar';

const OrganizationList = () => {

  const theme = useTheme();
  const navigate = useNavigate();
  const { height, width } = useWindowDimensions();

  const dispatch = useDispatch();
  const organizationList = useSelector(state => state.api.organizationList);
  const organizationListLoading = useSelector(state => state.api.organizationListLoading);
  const businessCategories = useSelector(state => state.api.businessCategories);
  const businessCategoriesLoading = useSelector(state => state.api.businessCategoriesLoading);
  const locationData = useSelector(state => state.common.geoLocationData);

  const [displayList, setDisplayList] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryId, setSelectedCategorId] = useState('all');


  const sortByLocation = (orgList, locationInfo) => {
    orgList = orgList.sort((a, b) => {
      let distanceA = getDistBetweenCordinates(a?.location?.coordinates, locationInfo?.coords);
      let distanceB = getDistBetweenCordinates(b?.location?.coordinates, locationInfo?.coords);
      if (distanceA == null) {
        distanceA = 20000; //max distance in km, possible between two coordinates on earth 
      }
      if (distanceB == null) {
        distanceB = 20000;  //max distance in km, possible between two coordinates on earth 
      }
      return (distanceA - distanceB)
    });
    return orgList;
  }

  const onClickCategory = (catId) => (e) => {
    setSelectedCategorId(catId);
    let sortedOrgList = sortByLocation(organizationList, locationData);
    if (catId !== 'all')
      sortedOrgList = sortedOrgList.filter(item => catId == item.businessCategoryId);
    setDisplayList(sortedOrgList);
  }

  const handleSearch = (searchText) => {
    if (searchText) {
      setSelectedCategorId("all")
      let filteredOrganizationList = organizationList.filter(org => org?.name?.toLowerCase()?.includes(searchText?.toLowerCase()));
      let sortedOrgList = sortByLocation(filteredOrganizationList, locationData);
      setDisplayList(sortedOrgList);
    } else {
      let sortedOrgList = sortByLocation(organizationList, locationData);
      setDisplayList(sortedOrgList);
    }
  }

  useEffect(() => {
    if (businessCategories) {
      let catList = [{ iconName: 'checklist', name: 'All', id: 'all' }, ...businessCategories];
      setCategoryList(catList);
    }
  }, [businessCategories])

  useEffect(() => {
    dispatch(fetchOrganizations());
    dispatch(fetchBusinessCategories());
  }, [])

  useEffect(() => {
    let sortedOrgList = sortByLocation(organizationList, locationData);
    setDisplayList(sortedOrgList);
    setIsRefreshing(false);
  }, [organizationList, locationData])

  return (
    <>
      <PrimaryLayout showQZLogo>
        <Box className="p-2 min-h-full">
          <SearchBar
            containerProps={{ sx: { my: 2 } }}
            sx={{ mt: '5px'}}
            cardProps={{
              elevation: 5,
              sx: { borderRadius: 5, p: 1.5, px: 3, maxWidth: 'md', mx: 'auto' }
            }}
            textFieldProps={{
              placeholder: "Search for organization",
              sx: { borderRadius: 5 },
            }}

            onChange={handleSearch}
          />
          <Box spacing={1} className="py-3 w-100 flex flex-row justify-center">
            <HorizontalItems>
              {
                businessCategoriesLoading ?
                  Array(parseInt(width / 150)).fill().map((item, idx) =>
                    <SkeletonCard key={idx} cardType="categories" />
                  )
                  :
                  categoryList.map(item =>
                    <Chip
                      key={item.id}
                      icon={<Icon fontSize="small" >{item.iconName.replace(/-/, "_")}</Icon>}
                      label={item.name}
                      variant="outlined"
                      color={item.id == selectedCategoryId ? "primary" : "default"}
                      onClick={onClickCategory(item.id)}
                      sx={{ marginRight: 1 }}
                    />
                  )
              }
            </HorizontalItems>
          </Box>
          <GridContainer spacing={2} justifyContent="center">
            {organizationListLoading ?
              <>
                {Array(parseInt(width / 300) * parseInt(height / 130)).fill().map((item, idx) =>
                  <GridItem key={idx}>
                    <OrganizationCard organizationData={null} isLoading={true} />
                  </GridItem>)
                }
              </>
              : displayList.map(organizationData =>
                <GridItem key={organizationData?.id}>
                  <OrganizationCard organizationData={organizationData} isLoading={organizationListLoading} />
                </GridItem>
              )
            }
          </GridContainer>
        </Box>
      </PrimaryLayout>
    </>
  )
}
export default OrganizationList; 