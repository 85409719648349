import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchGeoLocationByPinCode, setShowLocationSelector } from "../../redux/actions/commonActions";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import useGeoLocation from "../../hooks/useLocation";
import { Config } from "../../config/regions";

const LocationSelelctor = () => {

    const [geoLocationData, fetchGeoLocation] = useGeoLocation();
    const geoLocState = useSelector(state => state?.common?.geoLocationData);
    const showLocationSelector = useSelector(state => state?.common?.showLocationSelector);  // { disableAutoDetectButton, disablePinCodeEntry, isMandatory }
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [pinCode, setPinCode] = useState(geoLocState?.pinCode ?? "");
    const [formError, setFormError] = useState("");
    const [helperText, setHelperText] = useState("");


    const handleClose = (e, reason) => {
        if (showLocationSelector?.isMandatory && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            return;
        }
        setFormError("");
        dispatch(setShowLocationSelector(false));
    }

    const autoDetectLocation = () => {
        fetchGeoLocation(true);
        handleClose();
    }

    const applyPinCode = async () => {

        if (pinCode) {
            const countryCode = Config.defaultCountryCode;
            const locationData = await dispatch(fetchGeoLocationByPinCode(pinCode, countryCode, true));
            //console.log("locationData", locationData);
            if (locationData) {
                handleClose();
            } else {
                setFormError("Failed to find a location");
            }
        }

    }

    useEffect(() => {
        if (showLocationSelector) {
            setShowModal(true);
            if (geoLocState?.autoDetected && geoLocState?.pinCode) {
                setHelperText("PIN code has been auto detected!");
            } else if (!geoLocState?.pinCode) {
                setHelperText("Failed to auto detect the PIN code!");
            } else {
                setHelperText("");
            }
        } else {
            setShowModal(false);
        }
    }, [showLocationSelector]);

    useEffect(() => {
        if (geoLocState?.pinCode) {
            setPinCode(geoLocState?.pinCode);
        }
        if ( geoLocState?.autoDetected && geoLocState?.pinCode ) {
            setHelperText("PIN code has been auto detected!");
        }
    }, [geoLocState]);

    useEffect(() => {
        if (pinCode && geoLocState?.pinCode !== pinCode ) {
            setHelperText("");
        }
    }, [pinCode]);

    return (
        <>
            <Dialog
                open={showModal}
                onClose={handleClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{ sx: { borderRadius: 3 } }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="scroll-dialog-title">
                    Provide your location
                </DialogTitle>
                <DialogContent>
                    {!(showLocationSelector?.disableAutoDetectButton) &&
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <Button onClick={autoDetectLocation} variant="outlined">Auto Detect Location</Button>
                        </Box>
                    }
                    {!(showLocationSelector?.disablePinCodeEntry) &&
                        <>
                            {!(showLocationSelector?.disableAutoDetectButton) &&
                                <Divider>or</Divider>
                            }
                            <Typography sx={{ textAlign: 'center', my: 2 }}>
                                {`Enter an ${Config.defaultCountryCode == "AU" ? 'Australian' : 'Indian'} pincode`}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', my: 2, alignItems: "center" }}>
                                <TextField
                                    label="PIN Code"
                                    variant="filled"
                                    fullWidth
                                    name="code"
                                    onChange={(e) => setPinCode(e.target.value)}
                                    value={pinCode}
                                    helperText={formError ? formError : helperText}
                                    error={formError ? true : false}
                                    autoComplete='off'
                                    type="number"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Button variant="contained" color="primary" onClick={applyPinCode} disabled={!pinCode}>
                                                    Apply
                                                </Button>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </>
                    }
                </DialogContent>
                <DialogActions>
                    {!(showLocationSelector?.isMandatory) &&
                        <Button onClick={handleClose}>close</Button>
                    }
                </DialogActions>
            </Dialog>
        </>
    );
}
export default LocationSelelctor;