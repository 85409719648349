import { useDispatch, useSelector } from 'react-redux';
import { fetchOrganizationById, fetchProductCategoriesByOrgId, fetchProductsByOrgId, fetchServiceCategoriesByOrgId, fetchServicesbyOrgId } from '../../redux/actions/apiActions';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Box, Chip, Icon } from '@mui/material';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import ProductCard from './ProductCard';
import SkeletonCard from '../../components/Loaders/SkeletonCard';
import HorizontalItems from '../../components/HorizontalItems/HorizontalItems';
import SearchBar from '../../components/SearchBar/SearchBar';

const ProductList = () => {

  const dispatch = useDispatch();
  const orgDetails = useSelector(state => state.api.orgDetails);
  const productList = useSelector(state => state.api.productList);
  const productListLoading = useSelector(state => state.api.productListLoading);
  const productCategories = useSelector(state => state.api.productCategories);
  const productCategoriesLoading = useSelector(state => state.api.productCategoriesLoading);

  const { height, width } = useWindowDimensions();

  const [displayList, setDisplayList] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategoryId, setSelectedCategorId] = useState('all');

  const { orgRef } = useParams();


  const onClickCategory = (catId) => (e) => {
    setSelectedCategorId(catId);
    let sortedList = productList;
    if (catId !== 'all')
      sortedList = sortedList.filter(item => catId == item.productCategoryId);
    setDisplayList(sortedList);
  }

  const handleSearch = (searchText) => {
    if (searchText) {
      setSelectedCategorId("all")
      let filteredProductList = productList.filter(product => product?.name?.toLowerCase()?.includes(searchText?.toLowerCase()));
      setDisplayList(filteredProductList);
    } else {
      setDisplayList(productList);
    }
  }

  useEffect(() => {
    if (orgRef) {
      if (orgDetails?.orgRef !== orgRef) {
        dispatch(fetchOrganizationById(orgRef));
      }
    }
  }, [orgRef])

  useEffect(() => {
    if (orgDetails?.id) {
      dispatch(fetchProductsByOrgId(orgDetails.id));
      dispatch(fetchProductCategoriesByOrgId(orgDetails.id));
    }

  }, [orgDetails])

  useEffect(() => {
    setDisplayList(productList);
    setIsRefreshing(false);
  }, [productList])

  useEffect(() => {
    if (productCategories) {
      let catList = [{ iconName: 'checklist', name: 'All', id: 'all' }, ...productCategories];
      catList = catList.filter((item, idx) => catList.findIndex(elem => item.id == elem.id) === idx);
      catList = catList.sort((a, b) => (a?.position != null && b?.position != null ? (a.position - b.position) : 1));
      console.log(catList)
      setCategoryList(catList);
    }
  }, [productCategories])

  return (
    <Box className="p-2 min-h-full">
      <SearchBar
        containerProps={{ sx: { my: 2 } }}
        sx={{ mt: '5px'}}
        cardProps={{
          elevation: 5,
          sx: { borderRadius: 5, p: 1.5, px: 3, maxWidth: 'md', mx: 'auto' }
        }}
        textFieldProps={{
          placeholder: "Search for product",
          sx: { borderRadius: 5 },
        }}

        onChange={handleSearch}
      />
      <Box spacing={1} className="py-3 w-100 flex flex-row justify-center">
        <HorizontalItems>
          {
            productCategoriesLoading ?
              Array(parseInt(width / 150)).fill().map((item, idx) =>
                <SkeletonCard key={idx} cardType="categories" />)
              :
              categoryList.map(item =>
                <Chip
                  key={item.id}
                  icon={<Icon fontSize="small" >{item.iconName.replace(/-/, "_")}</Icon>}
                  label={item.name}
                  variant="outlined"
                  color={item.id == selectedCategoryId ? "primary" : "default"}
                  onClick={onClickCategory(item.id)}
                  sx={{ marginRight: 1 }}
                />
              )
          }
        </HorizontalItems>
      </Box>
      <GridContainer spacing={2} justifyContent="center">
        {productListLoading ?
          <>
            {Array(parseInt(width / 300) * parseInt(height / 150)).fill().map((item, idx) =>
              <GridItem key={idx} xs={12} sm={12} md={6} lg={4} xl={3} >
                <Box sx={{ p: 1 }}>
                  <ProductCard productData={null} isLoading={true} />
                </Box>
              </GridItem>)
            }
          </>
          : displayList.length > 0 ?
            displayList.map(productData =>
              <GridItem key={productData?.id} xs={12} sm={12} md={6} lg={4} xl={3}>
                <Box sx={{ p: 1 }}>
                  <ProductCard productData={productData} isLoading={productListLoading} />
                </Box>
              </GridItem>
            )
            :
            <>
              <Alert sx={{ justifyContent: 'center', borderRadius: 3, my: 10 }} severity="error">No products available</Alert>
            </>
        }
      </GridContainer>
    </Box>
  )
}


export default ProductList; 