import React from 'react';
import { Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Lightbox = ({ open, handleClose, imageUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        sx={{
          backgroundColor: 'background.paper',
          boxShadow: 24,
          padding: (theme) => theme.spacing(2, 4, 3),
          outline: 'none',
          maxWidth: '80%',
          maxHeight: '80%',
          overflowY: 'auto',
          position: 'relative',
        }}
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            top: (theme) => theme.spacing(1),
            right: (theme) => theme.spacing(1),
          }}
        >
          <CloseIcon />
        </IconButton>
        <img src={imageUrl} alt="Fullscreen" style={{ width: '100%' }} />
      </div>
    </Modal>
  );
};

export default Lightbox;